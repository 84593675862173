import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  IssueClosedIcon, IssueOpenedIcon, PencilIcon, FoldUpIcon,
} from '@primer/octicons-react';

import LoadingSwitch from 'common/ui/LoadingSwitch';

import ContentContainer from 'common/ui/ContentContainer';
import PageTitle from 'common/ui/PageTitle';
import { useHistory, useParams } from 'react-router';
import Card from 'common/ui/Card';
import { formatCurrency } from 'common/util/currency';
import { formatDateTime } from 'common/util/date';
import { CardSummaryCurrencyBlock, CardSummaryPercentBlock } from 'common/ui/CardSummaryBlock';
import { Link } from 'react-router-dom';
import { IconButton, LinkButton } from 'common/ui/Button';
import toastActions from 'common/ui/Toast/toastActions';
import classes from './investmentDetailPage.module.scss';
import { investmentDetailActions } from './investmentDetailPageSlice';
import { InvestmentDetailPageReduxState } from './investmentDetailPageTypes';
import LabelValueRow from '../../common/ui/LabelValueRow';
import { exchangeNetworkLookup } from '../shared/exchangeNetwork';
import { ReferralPayoutMode, referralPayoutModeLookup } from '../shared/referralPayoutMode';
import { investmentTransactionTypeLookup } from '../shared/investmentTransactionType';
import { referralDjModeLookup } from '../shared/referralDjMode';
import { InvestmentStatus, investmentStatusLookup } from '../shared/investmentStatus';
import InvestmentPendingSettlementModal from './investmentPendingSettlementModal';
import PartialPayoutModal from './PartialPayoutModal';

const InvestmentDetailPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  // Normally when user visits the page, has to load some data from remote
  // for showing, the `pageLoading` state is used to keep track this.
  const pageLoading = useSelector(
    (s: InvestmentDetailPageReduxState) => s.investmentDetail.pageLoading,
  );
  const detail = useSelector(
    (s: InvestmentDetailPageReduxState) => s.investmentDetail.detail,
  );
  const spotBalance = useSelector(
    (s: InvestmentDetailPageReduxState) => s.investmentDetail.spotBalance,
  );

  if (detail.future.transactions === undefined) {
    detail.future.transactions = [];
  }

  // When user visits the page, dispatch an action immediately to inform
  // saga to do page initialisation (mainly to load data needed).
  React.useEffect(() => {
    const idNumber = parseInt(id, 10);
    dispatch(investmentDetailActions.init(idNumber));
  }, [id]);

  return (
    <ContentContainer>
      <InvestmentPendingSettlementModal />
      <PartialPayoutModal />
      <PageTitle
        onBack={() => history.goBack()}
        buttons={[detail.id > 0 && detail.status !== InvestmentStatus.Closed && {
          label: 'Edit',
          icon: PencilIcon,
          onClick: () => history.push(`/member/investment/edit/${detail.id}`),
        }, detail.id > 0 && detail.status === InvestmentStatus.Active && {
          label: 'Partial Payout',
          icon: FoldUpIcon,
          onClick: () => dispatch(
            investmentDetailActions.setPartialPaymentPrompt(
              { id: detail.id, name: detail.formNo, amount: detail.amount },
            ),
          ),
        }, detail.id > 0 && detail.status === InvestmentStatus.Active && {
          label: 'To Pending Settlement',
          icon: IssueOpenedIcon,
          onClick: () => dispatch(
            investmentDetailActions.setPendingSettlementPrompt(
              { id: detail.id, name: detail.formNo },
            ),
          ),
        }, detail.id > 0 && detail.status === InvestmentStatus.PendingSettlement && {
          label: 'Close',
          icon: IssueClosedIcon,
          onClick: () => history.push(`/member/investment/close/${detail.id}`),
        }]}
      >
        {detail.formNo ? detail.formNo : 'Investment'}
      </PageTitle>
      <LoadingSwitch loading={pageLoading}>
        <Card
          title="Customer"
          titleRight={(
            <IconButton
              icon={PencilIcon}
              onClick={() => history.push(`/member/customer/edit/${detail.customerId}`)}
            />
              )}
        >
          <div className="row">
            <div className="col-md-6">
              <LabelValueRow label="Name">
                {detail.customer.name}
              </LabelValueRow>
            </div>
            <div className="col-md-6">
              <LabelValueRow label="ID Number">
                {detail.customer.idNumber}
              </LabelValueRow>
            </div>
            <div className="col-md-6">
              <LabelValueRow label="Contact Number">
                {detail.customer.contactNumber}
              </LabelValueRow>
            </div>
            <div className="col-12">
              <LabelValueRow label="Address">
                <div className="multiline">
                  {detail.customer.address}
                </div>
              </LabelValueRow>
            </div>
          </div>
        </Card>
        <div className="my-3" />
        <Card
          title="Customer Portal Login"
        >
          <div className="row">
            <div className="col-md-6">
              <LabelValueRow label="Form No.">
                {detail.formNo}
              </LabelValueRow>
            </div>
            <div className="col-md-6">
              <LabelValueRow label="Password">
                {detail.loginPassword}
              </LabelValueRow>
            </div>
            <div className="col-md-6">
              <LinkButton
                label="Copy Login Details"
                onClick={() => {
                  const text = `Please use the following details to login:\n\nForm No.: ${detail.formNo}\nPassword: ${detail.loginPassword}`;
                  navigator.clipboard.writeText(text).then(() => {
                    toastActions.success('Successfully copied login details to the clipboard.');
                  }, () => {
                    toastActions.error('Failed to copy the login details.');
                  });
                }}
              />
            </div>
          </div>
        </Card>
        <div className="my-3" />
        <Card title="Investment">
          <div className="row">
            <div className="col-md-6">
              <LabelValueRow label="Form No">
                {detail.formNo}
              </LabelValueRow>
            </div>
            <div className="col-md-6">
              <LabelValueRow label="Amount">
                {`${formatCurrency(detail.amount, 'two')} USDT`}
              </LabelValueRow>
            </div>
            <div className="col-md-6">
              <LabelValueRow label="Transaction Date">
                {detail.transactionDateTime}
              </LabelValueRow>
            </div>
            <div className="col-md-6">
              <LabelValueRow label="Maturity Date">
                {detail.maturityDate}
              </LabelValueRow>
            </div>
            <div className="col-12">
              <LabelValueRow label="Status">
                {investmentStatusLookup[detail.status].label}
              </LabelValueRow>
            </div>
            <div className="col-md-6">
              <LabelValueRow label="Bonus Payout">
                {`${detail.bonusPayoutRate}%`}
              </LabelValueRow>
            </div>
            <div className="col-md-6">
              <LabelValueRow label="Exchange Network">
                {detail.exchangeNetwork && exchangeNetworkLookup[detail.exchangeNetwork].label}
              </LabelValueRow>
            </div>
            <div className="col-md-6">
              <LabelValueRow label="Wallet Address">
                {detail.walletAddress}
              </LabelValueRow>
            </div>
            <div className="col-md-6">
              <LabelValueRow label="Transaction Hash">
                {detail.transactionHash}
              </LabelValueRow>
            </div>
          </div>
        </Card>
        <div className="my-3" />
        {detail.status === InvestmentStatus.Closed && detail.settlement && (
          <Card title="Final Settlement">
            <div className="row">
              {detail.totalPartialPayout && (
              <>
                <div className="col-md-6 mt-3">
                  <CardSummaryCurrencyBlock title="Total Partial Payout" value={detail.totalPartialPayout} />
                </div>
                <div className="col-md-6 mt-3" />
              </>
              )}

              <div className="col-md-6 mt-3">
                <CardSummaryCurrencyBlock title="Close Amount" value={detail.settlement.amount} />
              </div>
              <div className="col-md-6 mt-3">
                <CardSummaryCurrencyBlock title="Investor Profit" value={detail.settlement.investorProfit} />
              </div>
              <div className="col-md-6 mt-3">
                <CardSummaryCurrencyBlock title="Company Profit" value={detail.settlement.companyProfit} />
              </div>
              <div className="col-md-6 mt-3">
                <CardSummaryCurrencyBlock title="Referral Profit" value={detail.settlement.referralProfit} />
              </div>
              {detail.settlement.djProfit ? (
                <div className="col-md-6 mt-3">
                  <CardSummaryCurrencyBlock title="DJ Profit" value={detail.settlement.djProfit} />
                </div>
              ) : null}
            </div>
          </Card>
        )}
        <div className="my-3" />
        <Card title="Referral">
          <div className="row">
            <div className="col-12">
              <LabelValueRow label="Referral Name">
                {detail.referralName || '-'}
              </LabelValueRow>
            </div>
            <div className="col-md-6">
              <LabelValueRow label="Payout Mode">
                {referralPayoutModeLookup[detail.referralPayoutMode].label}
              </LabelValueRow>
            </div>
            <div className="col-md-6">
              <LabelValueRow label="Referral DJ Mode">
                {detail.referralDjMode && referralDjModeLookup[detail.referralDjMode].label}
              </LabelValueRow>
            </div>
            {detail.referralPayoutMode === ReferralPayoutMode.Instant && (
              <>
                <div className="col-md-6">
                  <LabelValueRow label="Referral Fee">
                    {`${formatCurrency(detail.referralFee, 'two')} USDT`}
                  </LabelValueRow>
                </div>
                <div className="col-md-6">
                  <LabelValueRow label="DJ Referral Fee">
                    {`${formatCurrency(detail.referralDjFee, 'two')} USDT`}
                  </LabelValueRow>
                </div>
                <div className="col-md-6">
                  <LabelValueRow label="Transaction Date">
                    {detail.referralTransactionDateTime}
                  </LabelValueRow>
                </div>
                <div className="col-md-6">
                  <LabelValueRow label="Exchange Network">
                    {exchangeNetworkLookup[detail.referralExchangeNetwork].label}
                  </LabelValueRow>
                </div>
                <div className="col-md-6">
                  <LabelValueRow label="Wallet Address">
                    {detail.referralWalletAddress}
                  </LabelValueRow>
                </div>
                <div className="col-md-6">
                  <LabelValueRow label="Transaction Hash">
                    {detail.referralTransactionHash}
                  </LabelValueRow>
                </div>
              </>
            )}
          </div>
        </Card>
        <div className="my-3" />
        <Card title="Transactions">
          <table className="table table-hover table-responsive-md">
            <thead>
              <tr>
                <th scope="col">Transaction Date</th>
                <th scope="col">Type</th>
                <th scope="col">Amount</th>
              </tr>
            </thead>
            <tbody>
              {detail.transactions.map((i) => (
                <tr key={i.id}>
                  <td>{formatDateTime(i.dateTime)}</td>
                  <td>{investmentTransactionTypeLookup[i.type]?.label}</td>
                  <td>{formatCurrency(i.amount, 'two')}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Card>
        <div className="my-3" />
        <Card title="Spot Trades">
          <table className="table table-hover table-responsive-md">
            <thead>
              <tr>
                <th scope="col">Transaction Date</th>
                <th scope="col">Trade</th>
                <th scope="col">Type</th>
                <th scope="col">Unit Price</th>
                <th scope="col">Quantity</th>
                <th scope="col">Total</th>
              </tr>
            </thead>
            <tbody>
              {detail.spotTrades.map((i) => (
                <tr key={`${i.type}-${i.id}`} className={i.type === 'buy' ? 'text-success' : 'text-warning'}>
                  <td>{formatDateTime(i.dateTime)}</td>
                  <td>{i.tradeName}</td>
                  <td>{i.type}</td>
                  <td>{formatCurrency(i.unitPrice, 'three')}</td>
                  <td>{formatCurrency(i.quantity, 'four')}</td>
                  <td>{formatCurrency(i.total, 'two')}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Card>
        <div className="my-3" />
        <Card title="Spot Balance">
          <table className="table table-hover table-responsive-md mb-3">
            <thead>
              <tr>
                <th scope="col">Trade</th>
                <th scope="col">Quantity</th>
                <th scope="col">Price</th>
                <th scope="col">Holding Total</th>
              </tr>
            </thead>
            <tbody>
              {spotBalance.data.map((i) => (
                <tr key={i.name}>
                  <td>{i.name}</td>
                  <td>{formatCurrency(i.balance, i.name === 'USDT' ? 'two' : 'six')}</td>
                  <td>{formatCurrency(i.price, 'six')}</td>
                  <td>{formatCurrency(i.total, 'six')}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="row">
            <div className="col-md-6">
              <CardSummaryCurrencyBlock title="Total Market Value" value={spotBalance.totalMarketValue} />
            </div>
            <div className="col-md-6">
              <CardSummaryPercentBlock title="Unrealized Profit/Loss" value={spotBalance.unrealizedProfitLoss} />
            </div>
          </div>
        </Card>
        <div className="my-3" />
        <Card title="Future Account Settlements">
          <table className="table table-hover">
            <thead>
              <tr>
                <th scope="col">Settlement Date</th>
                <th scope="col">Before Balance</th>
                <th scope="col">Amount</th>
                <th scope="col">Profit/Loss %</th>
                <th scope="col">After Balance</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Initial Balance:</td>
                <td>{formatCurrency(detail.future.initialAmount, 'two')}</td>
                <td />
                <td />
                <td />
              </tr>
              {/* {detail.future.transactions.slice(1).map((i, index) => ( */}
              {/*  <tr key={i.id}
              className={(index !== 0 && i.amount > 0) ? 'text-success' : 'text-danger'}
              > */}
              {/*    <td>{formatDateTime(i.dateTime)}</td> */}
              {/*    <td>{formatCurrency(i.accountAmount, 'two')}</td> */}
              {/*    <td>{formatCurrency(i.percent, 'four')}</td> */}
              {/*    <td>{formatCurrency(i.amount, 'two')}</td> */}
              {/*    <td>{formatCurrency(i.toSpot, 'two')}</td> */}
              {/*  </tr> */}
              {/* ))} */}
              {detail.future.settlements.map((i) => (
                <tr key={i.id}>
                  <td>{formatDateTime(i.dateTime)}</td>
                  <td>{formatCurrency(i.beforeSettlementAmount, 'two')}</td>
                  <td className={i.percent > 0 ? 'text-success' : 'text-danger'}>
                    {formatCurrency(i.settlementAmount, 'four')}
                    {i.toSpot > 0 && (
                      <div>
                        <small className="font-italic text-black-50">
                          To
                          Spot:
                          {formatCurrency(i.toSpot, 'four')}
                        </small>
                      </div>
                    )}
                  </td>
                  <td
                    className={i.percent > 0 ? 'text-success' : 'text-danger'}
                  >
                    {formatCurrency(i.percent, 'two')}
                  </td>
                  <td>{formatCurrency(i.afterSettlementAmount, 'two')}</td>
                  {/* <td>{formatCurrency(i.toSpot, 'two')}</td> */}
                </tr>
              ))}
              <tr>
                <td>Current Balance:</td>
                <td>{formatCurrency(detail.future.balanceAmount, 'two')}</td>
                <td />
                <td>Current Total To Spot:</td>
                <td>
                  {formatCurrency(detail.future.settlements.reduce(
                    (item, current) => item + current.toSpot, 0,
                  ), 'two')}
                </td>
              </tr>
            </tbody>
          </table>
        </Card>
        {detail.childInvestments.length > 0 && (
          <>
            <div className="my-3" />
            <Card title="Referral/Next Accounts">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">Form No</th>
                    <th scope="col">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {detail.childInvestments.map((i) => (
                    <tr key={i.id}>
                      <td>
                        <Link to={`/member/investment/detail/${i.id}`}>
                          {i.formNo}
                        </Link>
                      </td>
                      <td>{formatCurrency(i.amount, 'two')}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Card>
          </>
        )}
      </LoadingSwitch>
    </ContentContainer>
  );
};

export default InvestmentDetailPage;
