import axios, { AxiosResponse } from 'axios';
import { ContextReduxState } from 'context/contextTypes';
import produce from 'immer';
import { put } from 'redux-saga/effects';
import {
  call, SagaGenerator, select,
} from 'typed-redux-saga';
import { tradeActions, TradePrice } from './tradeSlice';

// 5 minutes in milliseconds
const CACHE_VALIDITY = 1000 * 60 * 5;

/**
 * Get the trade prices of specified symbols.
 */
// eslint-disable-next-line import/prefer-default-export
export function* getTradePrices(coinGeckoIds: string[]): SagaGenerator<Record<string, TradePrice>> {
  const oldLookup = yield* select((s: ContextReduxState) => s.context.trade.tradePriceLookup);

  // Determine can cache satisfy
  let canReturnFromCache = Object.keys(oldLookup).length > 0;
  coinGeckoIds.forEach((id) => {
    if (!oldLookup[id] || (new Date().getTime() - oldLookup[id].lastUpdated) > CACHE_VALIDITY) {
      canReturnFromCache = false;
    }
    return canReturnFromCache;
  });

  if (canReturnFromCache) {
    return oldLookup;
  }

  const allIds = coinGeckoIds.join(',');

  const resp = (yield* call(
    axios.get, `https://api.coingecko.com/api/v3/simple/price?ids=${allIds}&vs_currencies=usd`,
  )) as AxiosResponse<Record<string, { usd: number }>>;

  const lookup = produce(oldLookup, (draft) => {
    const now = new Date().getTime();
    Object.entries(resp.data).forEach(([id, price]) => {
      let latestPrice = price.usd;
      if (id === 'floki') {
        latestPrice *= 1000;
      }
      draft[id] = {
        lastUpdated: now,
        price: latestPrice,
      };
    });
  });

  yield put(tradeActions.setTradePriceLookup(lookup));
  return lookup;
}
