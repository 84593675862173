import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import LoadingSwitch from 'common/ui/LoadingSwitch';

import { useHistory, useParams } from 'react-router';
import ContentContainer from 'common/ui/ContentContainer';
import PageTitle from 'common/ui/PageTitle';
import Card from 'common/ui/Card';
import FooterActionContainer from 'common/ui/FooterActionContainer';
import {
  FormikDatePicker, FormikDropDownList, FormikForm, FormikNumberField,
  FormikValidationMessage,
} from 'common/form';
import Button from 'common/ui/Button';
import LabelValueRow from 'common/ui/LabelValueRow';
import { formatDateTime } from 'common/util/date';
import { formatCurrency } from 'common/util/currency';
import { Validate } from 'common/form/validations';
import { LoadingAlert } from 'common/ui/Alert';
import Big from 'big.js';
import classes from './spotDetailPage.module.scss';
import { spotDetailActions } from './spotDetailPageSlice';
import { SellSpotForm, SpotDetailPageReduxState } from './spotDetailPageTypes';
import validateSellTradeForm from './spotDetailPageValidation';
import { SpotSellFor, spotSellForValues } from './spotSellFor';
import { selectSpotSellInvestmentActions } from '../selectSpotSellInvestment/selectSpotSellInvestmentModalSlice';
import SelectSpotSellInvestmentModal from '../selectSpotSellInvestment';

const SpotDetailPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  // Normally when user visits the page, has to load some data from remote
  // for showing, the `pageLoading` state is used to keep track this.
  const pageLoading = useSelector(
    (s: SpotDetailPageReduxState) => s.spotDetail.pageLoading,
  );
  const formLoading = useSelector(
    (s: SpotDetailPageReduxState) => s.spotDetail.formLoading,
  );
  const detail = useSelector(
    (s: SpotDetailPageReduxState) => s.spotDetail.detail,
  );

  // When user visits the page, dispatch an action immediately to inform
  // saga to do page initialisation (mainly to load data needed).
  React.useEffect(() => {
    const idNumber = parseInt(id, 10);
    dispatch(spotDetailActions.init({ id: idNumber }));
  }, []);

  const onSubmit = React.useCallback((f) => {
    dispatch(spotDetailActions.submit(f));
  }, [dispatch]);

  const composeValidateSellTradeForm = React.useMemo<Validate<SellSpotForm>>(
    () => validateSellTradeForm(detail), [detail],
  );

  return (
    <ContentContainer>
      <SelectSpotSellInvestmentModal />
      <PageTitle
        onBack={() => history.goBack()}
      >
        {detail.symbol || 'Trade Detail'}
      </PageTitle>
      <LoadingSwitch loading={pageLoading}>
        <Card title="Detail">
          <div className="row">
            <div className="col-12">
              <LabelValueRow label="Name">
                {detail.name}
              </LabelValueRow>
            </div>
            <div className="col-12">
              <LabelValueRow label="Symbol">
                <div
                  className={classes['color-icon']}
                  style={{ backgroundColor: detail.color }}
                >
                  &nbsp;
                </div>
                {detail.symbol}
              </LabelValueRow>
            </div>
            <div className="col-12">
              <LabelValueRow label="Balance">
                {formatCurrency(detail.balance, 'four')}
              </LabelValueRow>
            </div>
          </div>
        </Card>
        <div className="my-3" />
        <Card title="Transactions">
          <table className="table table-hover table-responsive-md">
            <thead>
              <tr>
                <th scope="col">Transaction Date</th>
                <th scope="col">Type</th>
                <th scope="col">Unit Price</th>
                <th scope="col">Quantity</th>
                <th scope="col">Total</th>
                <th scope="col">% of Fund</th>
                <th scope="col">% of Qty</th>
              </tr>
            </thead>
            <tbody>
              {detail.transactions.map((i) => (
                <tr key={i.id}>
                  <td>{formatDateTime(i.dateTime)}</td>
                  <td>{i.type}</td>
                  <td>{formatCurrency(i.unitPrice, 'three')}</td>
                  <td>{formatCurrency(i.quantity, 'six')}</td>
                  <td>{formatCurrency(i.total, 'two')}</td>
                  <td>{i.usePercent && i.type === 'buy' && `${formatCurrency(i.usePercent, 'four')}%`}</td>
                  <td>{i.usePercent ? i.type === 'sell' && `${formatCurrency(i.usePercent, 'four')}%` : 'Specific'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Card>
        <div className="my-3" />
        <Card title="Balance">
          <table className="table table-hover table-responsive-md">
            <thead>
              <tr>
                <th scope="col">Quantity</th>
                <th scope="col">Price (USDT)</th>
                <th scope="col">Holding Value (USDT)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{formatCurrency(detail.balance, 'six')}</td>
                <td>{formatCurrency(detail.price, 'six')}</td>
                <td>{formatCurrency(detail.holdingValue, 'six')}</td>
              </tr>
            </tbody>
          </table>
        </Card>
        <div className="my-3" />
        <Card title="Sell Trade">
          <FormikForm
            initialValues={{
              dateTime: null,
              unitPrice: null,
              quantity: null,
              total: null,
              transactionFee: null,
              sellFor: SpotSellFor.All,
              sellForTargets: { isConfirm: false },
            }}
            onSubmit={onSubmit}
            validate={composeValidateSellTradeForm}
          >
            {({ handleSubmit, setFieldValue, values }) => (
              <form onSubmit={handleSubmit}>
                <LoadingAlert loading={formLoading} />
                <div className="row">
                  <div className="col-12">
                    <FormikDatePicker
                      id={nameof(values.dateTime)}
                      name={nameof(values.dateTime)}
                      label="Transaction Date Time"
                      time
                    />
                  </div>
                  <div className="col-12">
                    <FormikNumberField
                      id={nameof(values.unitPrice)}
                      name={nameof(values.unitPrice)}
                      label="Unit Price"
                      inputPreLabel="USDT"
                      decimal={3}
                      onBlur={(value) => {
                        if (value && values.quantity) {
                          const rawTotal = new Big(values.quantity)
                            .times(value || 0)
                            .round(2);

                          const transactionFee = rawTotal
                            .mul(0.001);

                          const total = rawTotal
                            .sub(transactionFee);

                          setFieldValue(nameof(values.total), total.toNumber());
                          setFieldValue(
                            nameof(values.transactionFee), transactionFee.toNumber(),
                          );
                        }
                      }}
                    />
                  </div>
                  <div className="col-12">
                    <FormikDropDownList
                      id={nameof(values.sellFor)}
                      name={nameof(values.sellFor)}
                      label="Sell For"
                      values={spotSellForValues}
                      onChange={(value) => {
                        if (value === SpotSellFor.All) {
                          setFieldValue(nameof(values.sellForTargets), []);
                        }

                        setFieldValue(nameof(values.quantity), null);
                        setFieldValue(nameof(values.total), null);
                        setFieldValue(nameof(values.transactionFee), null);
                      }}
                    />
                  </div>
                  {values.sellFor === SpotSellFor.All ? (
                    <div className="col-12">
                      <FormikNumberField
                        id={nameof(values.quantity)}
                        name={nameof(values.quantity)}
                        decimal={6}
                        label={`Quantity (Available: ${formatCurrency(detail.balance, 'six')})`}
                        onBlur={(value) => {
                          if (value) {
                            const rawTotal = new Big(value)
                              .times(values.unitPrice || 0)
                              .round(2);

                            const transactionFee = rawTotal
                              .mul(0.001);

                            const total = rawTotal
                              .sub(transactionFee);

                            setFieldValue(nameof(values.total), total.toNumber());
                            setFieldValue(
                              nameof(values.transactionFee), transactionFee.toNumber(),
                            );
                          }
                        }}
                      />
                    </div>
                  ) : (
                    <div className="col-12">
                      <div className="form-group">
                        {/* No input associated */}
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label>Investments to Include</label>
                        <div>
                          { values.sellForTargets.isConfirm && (
                            <div className="mb-2">
                              {'Investment(s) Selected: '}
                              <strong>{values.sellForTargets.investmentCount}</strong>
                              <br />
                              {'Total Quantity: '}
                              <strong>{values.sellForTargets.totalQuantity}</strong>
                            </div>
                          )}
                          <Button
                            type="button"
                            label="Select Investment"
                            onClick={() => dispatch(selectSpotSellInvestmentActions.init({
                              availableQuantity: detail.balance,
                              callback: (result) => {
                                if (result.isConfirm) {
                                  setFieldValue(nameof(values.quantity), result.totalQuantity);
                                  setFieldValue(nameof(values.sellForTargets), result);

                                  const rawTotal = new Big(result.totalQuantity)
                                    .times(values.unitPrice || 0)
                                    .round(2);

                                  const transactionFee = rawTotal
                                    .mul(0.001);

                                  const total = rawTotal
                                    .sub(transactionFee);

                                  setFieldValue(nameof(values.total), total.toNumber());
                                  setFieldValue(
                                    nameof(values.transactionFee), transactionFee.toNumber(),
                                  );
                                }
                              },
                              tradeId: detail.id,
                              lastResult: values.sellForTargets,
                            }))}
                          />
                          <FormikValidationMessage name={nameof(values.quantity)} />
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="col-12">
                    <FormikNumberField
                      id={nameof(values.transactionFee)}
                      name={nameof(values.transactionFee)}
                      label="Transaction Fee (Default: 0.1%)"
                      inputPreLabel="USDT"
                      decimal={2}
                    />
                  </div>
                  <div className="col-12">
                    <FormikNumberField
                      id={nameof(values.total)}
                      name={nameof(values.total)}
                      label="Total"
                      inputPreLabel="USDT"
                      decimal={2}
                    />
                  </div>
                </div>
                <FooterActionContainer>
                  <Button type="submit" isLoading={formLoading.isLoading} label="Save" />
                </FooterActionContainer>
              </form>
            )}
          </FormikForm>
        </Card>
      </LoadingSwitch>
    </ContentContainer>
  );
};

export default SpotDetailPage;
